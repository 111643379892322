import React, { useState } from "react"
import { graphql } from "gatsby"
import styles from "../styles/news-listing.module.css"
import HeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import News from "../components/news/news"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const NewsListing = ({ data, location }) => {
  const cookieConsentRef = React.createRef();
  const [mehrLaden, setMehrLaden] = useState(false)
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <HeaderMenu url={location.href} page="news-listing" />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>
      <header className={styles.header}>
        {data.datoCmsSeiten.header[0].softHeadline && (
          <div
            className={styles.h1}
            dangerouslySetInnerHTML={{
              __html: data.datoCmsSeiten.header[0].softHeadline,
            }}
          ></div>
        )}
        {!data.datoCmsSeiten.header[0].softHeadline &&
          data.datoCmsSeiten.header[0].headline && (
            <h1>{data.datoCmsSeiten.header[0].headline}</h1>
          )}
        <div
          className={styles.p}
          dangerouslySetInnerHTML={{
            __html: data.datoCmsSeiten.header[0].text,
          }}
        ></div>
      </header>
      <main
        className={`${styles.mainContainer} ${
          mehrLaden ? styles.fullHeight : ""
        }`}
      >
        <News page="news-listing" />
      </main>
      <button
        className={`${styles.loadMoreButton} `}
        onClick={() => {
          setMehrLaden(!mehrLaden)
        }}
        style={mehrLaden ? { display: "none" } : {}}
      >
        Mehr laden
      </button>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export default NewsListing

export const query = graphql`
  query NewsListing {
    datoCmsSeiten(name: { eq: "News" }) {
      seo {
        description
        title
        twitterCard
      }
      header {
        ... on DatoCmsHeaderTextBild {
          id
          headline
          text
          softHeadline
        }
      }
    }
  }
`
